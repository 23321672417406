import React from "react";
import { Layout } from "antd";
import styles from "./index.module.css";
import { useTheme } from "../ReactHooks/useTheme";
import AppHeader from "./AppHeader";
import { cls } from "../../utils/frontend/utils";
const { Header, Content, Footer } = Layout;
import Head from "next/head";
import { COLORS } from "../../const";

export default function AppEntryLayout({
  children,
  title,
  footer: CustomizedFooter = null,
  ogImage,
  ogTitle,
  ogUrl,
  ogContent,
}) {
  const [theme] = useTheme();

  return (
    <Layout
      className={cls(
        styles,
        ["AppEntryLayout"],
        ["AppEntryLayout", theme === "dark" ? "dark" : "light"]
      )}
    >
      <Head>
        <title>{title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:image" content={ogImage} />
        <meta property="og:title" content={ogTitle || "SkyHarbor"} />
        <meta property="og:description" content={ogContent} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:site_name" content="SkyHarbor" />
        <meta name="twitter:title" content={ogTitle || "SkyHarbor"} />
        <meta name="twitter:description" content={ogContent} />
        <meta name="twitter:image" content={ogImage} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content={ogUrl} />
      </Head>
      <Header>
        <AppHeader />
      </Header>
      <Content>{children}</Content>
      {CustomizedFooter && (
        <Footer style={{ textAlign: "center", background: COLORS["gray-ext1"] }}>
          <CustomizedFooter />
        </Footer>
      )}
    </Layout>
  );
}
